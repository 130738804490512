import React from 'react';
import { graphql } from 'gatsby';
// import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import Layout from '../components/layout';
import PostSEO from '../components/post-seo';


// Ref: https://github.com/erikthedeveloper/erikaybar.name-gatsby
//  https://erikaybar.name/adding-tags-to-a-gatsby-blog

const TagPage = ({
  pageContext,
  //data,
}) => {
  const {tag} = pageContext;
  //const { markdown } = data;

  const titleText = `Posts Tagged "${tag}"`;

  return (
    <Layout>
      <PostSEO title={`Posts tagged ${tag}`} description="DuneSea Development Blog"  />
      <h1> {titleText} </h1>
      <Container>
        AAA bbb
      </Container>
    </Layout>
    
  );
}


export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {tags: {in: [$tag]}, published: {ne: false}}}
    ) {
      totalCount
      edges {
        node {
          excerpt
          frontmatter {
            title
            tags
            date(formatString: "MMMM DD, YYYY")
            slug
            published
            featuredImage {
              childImageSharp {
                fixed(width: 125, height: 125) {
                  src
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TagPage;